import React from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import container from './container.css';

const Container = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { fullWidth?: boolean; narrow?: boolean }
>(({ children, className, fullWidth, narrow, ...props }, ref) => (
  <div
    className={joinClassNames(className, container({ fullWidth: !!fullWidth, narrow: !!narrow }))}
    {...props}
    ref={ref}
  >
    {children}
  </div>
));

Container.displayName = 'ColumntContainer';

export default Container;
