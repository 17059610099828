import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  copyright,
  footer,
  footerGrid,
  footerLink,
  footerLinkBase,
  linksNav,
  linksNavHeading,
  linksNavList,
  socialsList,
  socialsListItem,
} from './appFooterLarge.css';
import Container from './Container';
import sc from './styled';

const FooterLink = sc(Link)(footerLink);

const Footer = sc.footer(footer);

const LinksNav = sc.nav(linksNav);

const LinksNavHeading = sc.h3(linksNavHeading);

const LinksNavList = sc.ul(linksNavList);

const FooterGrid = sc(Container)(footerGrid);

const Copyright = sc.div(copyright);

const SocialsList = sc.ul(socialsList);
const SocialsListItem = sc.li(socialsListItem);
const SocialListLink = sc.a(footerLinkBase);

const AppFooterLarge = ({ className }: { className?: string }) => (
  <Footer className={className}>
    <FooterGrid>
      <div>
        <Copyright>© 2023 Introwise, Inc.</Copyright>
        <SocialsList>
          <SocialsListItem>
            <SocialListLink href="https://www.youtube.com/c/Introwise" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} title="YouTube" titleId="footer-icon-youtube" />
            </SocialListLink>
          </SocialsListItem>
          <SocialsListItem>
            <SocialListLink href="https://linkedin.com/company/introwise" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} title="LinkedIn" titleId="footer-icon-linkedin" />
            </SocialListLink>
          </SocialsListItem>
          <SocialsListItem>
            <SocialListLink href="https://instagram.com/introwise" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} title="Instagram" titleId="footer-icon-instagram" />
            </SocialListLink>
          </SocialsListItem>
          <SocialsListItem>
            <SocialListLink href="https://facebook.com/introwise" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} title="Facebook" titleId="footer-icon-facebook" />
            </SocialListLink>
          </SocialsListItem>
          <SocialsListItem>
            <SocialListLink href="https://twitter.com/introwise" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} title="Twitter" titleId="footer-icon-twitter" />
            </SocialListLink>
          </SocialsListItem>
        </SocialsList>
      </div>
      <LinksNav aria-label="Footer navigation">
        <div>
          <LinksNavHeading>Product</LinksNavHeading>
          <LinksNavList>
            <li>
              <FooterLink to="/pricing">Pricing</FooterLink>
            </li>
            <li>
              <FooterLink to="/faq">FAQ</FooterLink>
            </li>
            <li>
              <FooterLink to="/feedback">Roadmap</FooterLink>
            </li>
            <li>
              <FooterLink to="/help">Help center</FooterLink>
            </li>
          </LinksNavList>
        </div>
        <div>
          <LinksNavHeading>Use cases</LinksNavHeading>
          <LinksNavList>
            <li>
              <FooterLink to="/uses/coaches">Coaches</FooterLink>
            </li>
            <li>
              <FooterLink to="/uses/tutors">Tutors</FooterLink>
            </li>
            <li>
              <FooterLink to="/uses/psychologists">Psychologists</FooterLink>
            </li>
            <li>
              <FooterLink to="/uses/scheduling-payments-software">Consultants</FooterLink>
            </li>
          </LinksNavList>
        </div>
        <div>
          <LinksNavHeading>Company</LinksNavHeading>
          <LinksNavList>
            <li>
              <FooterLink to="/careers">Careers</FooterLink>
            </li>
            <li>
              <FooterLink to="/blog">Blog</FooterLink>
            </li>
          </LinksNavList>
        </div>
        <div>
          <LinksNavHeading>Resources</LinksNavHeading>
          <LinksNavList>
            <li>
              <FooterLink to="/privacy-policy">Privacy</FooterLink>
            </li>
            <li>
              <FooterLink to="/terms-of-service">Terms</FooterLink>
            </li>
            <li>
              <FooterLink to="/contact">Contact&nbsp;us</FooterLink>
            </li>
          </LinksNavList>
        </div>
      </LinksNav>
    </FooterGrid>
  </Footer>
);

export default AppFooterLarge;
